.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General Responsive Container */
.app-container {
  width: 100%;
  overflow-x: hidden;
}

/* Loading Animation */
.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #D4AF37;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Media Queries */
@media screen and (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Navigation adjustments */
  .navbar-brand {
    font-size: 24px;
  }

  .navbar-toggle {
    margin-right: 0;
  }

  /* Section spacing */
  section {
    padding: 60px 0;
  }

  /* Text adjustments */
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  /* Smaller devices */
  .col-xs-12 {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Adjust section padding */
  section {
    padding: 40px 0;
  }

  /* Further reduce text sizes */
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  /* Stack grid items */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  /* Adjust buttons */
  .btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  /* Improve touch targets */
  .nav > li > a,
  .btn,
  .clickable {
    min-height: 44px;
    min-width: 44px;
    padding: 12px 20px;
  }

  /* Remove hover states */
  .nav > li > a:hover,
  .btn:hover {
    transition: none;
  }
}

/* Landscape Mode */
@media screen and (max-height: 500px) and (orientation: landscape) {
  /* Adjust header height */
  .header-content {
    padding: 40px 0;
  }

  /* Adjust navigation */
  .navbar {
    padding: 5px 0;
  }
}

/* High DPI Screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Optimize images for retina displays */
  img {
    image-rendering: -webkit-optimize-contrast;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .loader-container {
    background: #1a1a1a;
  }

  .loader {
    border-color: #333;
    border-top-color: #D4AF37;
  }
}

/* Print Styles */
@media print {
  .app-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .navbar,
  .footer {
    display: none;
  }
}
